<template>
    <div class="takeALook">
        <rx-navBar v-if="fromType == '1' || fromType == '3'" title="带看" ></rx-navBar>
        <rx-navBar v-else title="带看" androidOrIOSFlag="true"></rx-navBar>
        <!-- 搜索栏-->
        <div class="search">
            <!-- <div class="search-icon"></div>
            <van-search class="searchBody" v-model="searchValue" placeholder="合同编号/房源地址/租客姓名、手机号" /> -->
            <van-search shape v-model="searchValue" placeholder="合同编号/房源地址/租客姓名、手机号" @search ="onSearch" background ="#F4F4F4"/>
        </div>
        <div class="types" v-if="houseRelevantFlag">
            <!-- 点击显示和隐藏下拉菜单 -->
            <div class="type" @click="DropdownMenuShow = true">{{type}}</div>
            <div :class="DropdownMenuShow?'small-icon-rotate':'small-icon'"></div>
        </div>

        <div class="reviews" v-show="isAll">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    :finished-text="isEmptuyFlag ? '':'已经到底了'"
                    @load="this.onLoad"
                    class="van-clearfix"
                >
                    <div class="review" v-for="(item,index) in takeALookList" :key="index">
						        <div :class="item.appointmentStatus=='0'?'top-title-orange':item.appointmentStatus=='1'?'top-title-red':item.appointmentStatus=='2'?'top-title-green':'top-title-gray'">
                            {{item.labelText}}
                        </div>
                        <div class="position">
                            <span class="position-span-left">{{item.houseTypeStr}}</span>
                            <span class="position-span-right">·{{item.roomDetailedAddress}}</span>
                        </div>
                        <div v-if="fromType !=3" class="tel_IMg" @click="showMobile(item)">
                            <img class="tel" src="../../assets/images/tel.png">
                        </div>


                        <div class="details">
                            <div class="info">
                                <div class="order">潜客姓名</div>
                                <div class="order-name">{{item.userName}}</div>
                            </div>
                            <div class="line"></div>
                            <div class="info">
                                <div class="order">租金</div>
                                <div class="order-name">{{item.offerPrice ? item.offerPrice+'元':'暂无'}}</div>
                            </div>
                            <div class="line"></div>
                            <div class="info">
                                <div class="order">户型</div>
                                <div class="order-name">{{item.apartment||'暂无'}}</div>
                            </div>
                        </div>
                        <div class="reject">
                            <div class="reject-num"><span>带看时间：</span><span class="span-num">{{item.appointmentTime}}</span></div>
                            <div v-if="item.appointmentStatus==1" class="reject-reason"><span>原因:</span>{{item.failReason}}</div>
                            <div v-else class="reject-reason"><span>结果：</span>{{item.labelText}}</div>
                        </div>
                        <div class="bottom" v-if="item.appointmentStatus == '0' || item.isClockIn || item.publicDeviceId">
							          <div  style=" height: 60px;width: 240px;">
                                <div v-if="item.publicDeviceId && fromType !=3" style=" height: 60px;width: 240px;" @click="seeWithGetPassword(item)">
                                    <div :class="item.publicDeviceId? 'pwdShow':'pwdHide'">
                                        <img class="pwd_IMG" src="../../assets/images/RepairPending-getPwd.png">
                                        <span class="getPwd">获取密码</span>
                                    </div>
                                </div>

                            </div>
							<get-door-password :doorPassWord="temporaryPublicDoorPwd" :publicDoorPassWord="temporaryDoorPassWord"  :isMobileShow="isMobileShow"
                                               @hideDialog="hideDialog"></get-door-password>
							<button v-if="item.isClockIn" class="btn2">已带看</button>
							<button v-if="item.appointmentStatus == '0'"  class="btn" @click="checkTakeALook(item)">带看打卡</button>
                        </div>
                    </div>
                </van-list>
                <!--空状态显示-->
                <common-empty v-if="isEmptuyFlag"></common-empty>
            </van-pull-refresh>
<!--            <common-empty v-if="noContent"></common-empty>-->
        </div>
        <div class="buttonWrap" v-if="fromType == '1'">
			<button class="addUser" @click="pageJump">+添加带看</button>
        </div>

        <div class="fillContent"></div>
        <van-popup v-model="DropdownMenuShow" round position="bottom">
			<van-picker show-toolbar value-key="dictionaryTitle" :columns="typeList" @cancel="DropdownMenuShow = false" @confirm="selectType" />
        </van-popup>
		<dial-mobile :ownerMobile="temporaryOwnerMobile" :userName="temporaryOwnerName" dialogTitle="联系潜客" :mobileShow="MobileShow" @hideDialog="hideDialog2">
        </dial-mobile>

    </div>
</template>

<script>
    import {
        NavBar,
        Search,
        DropdownMenu,
        DropdownItem,
        Popup,
        Picker,
        PullRefresh,
        Toast,
        List
    } from 'vant';
    import {
        seeWithList,
		queryBaseData,
		seeWithGetPassword
    } from "../../getData/getData";
    import rxNavBar from '../../components/rongxun/rx-navBar/navBar'
    import dialMobile from '../../components/rongxun/rx-dialMobile/dialMobile'
	import {
		globaluserId,
        getStaffId,
		responseUtil
	} from '../../libs/rongxunUtil'
	import util from "../contract/util2"
	import getDoorPassword from '../../components/rongxun/rx-doorPassword/GetDoorPassword'
    export default {
        components: {
            [NavBar.name]: NavBar,
            [Search.name]: Search,
            [DropdownMenu.name]: DropdownMenu,
            [DropdownItem.name]: DropdownItem,
            [Popup.name]:Popup,
            [Picker.name]:Picker,
            [PullRefresh.name]:PullRefresh,
            [Toast.name]:Toast,
            [List.name]:List,
            rxNavBar,
            dialMobile,
            getDoorPassword,
        },
        name: "takeALook",
        data() {
            return {
                houseRelevantFlag: true,
                roomHouse_id: '',
                fromType:'',// 入口区别标志
				        isEmptuyFlag:false,
                id:'',
                DropdownMenuShow:false,
				        MobileShow: false,
                type:'',
                searchValue:'',
                typeValue:'',
                pwdShow: true,
                typeList: [],
                temporaryOwnerMobile:'',
                temporaryOwnerName:'',
                isAll: true,
                refreshing:false,
                loading: false,
                finished: false,
                totalPage:'',
                pageCount:'',
                currentPage:1,
                //所有状态列表
                takeALookList: [],
                temporaryDoorPassWord: '',
                temporaryPublicDoorPwd: '',
                isMobileShow: false,
                privatePwd: '', //密码  间
                publicPwd: '', //密码  公区
                //空状态
                noContent : false
            }
        },
        created:function(){
            this.id=this.$route.query.id
            this.userName=this.$route.query.userName
            this.fromType=this.$route.query.fromType    //from 标志 1：潜客详情  3:房源相关  区别来源工作台和潜客详情1、房源相关 ，从工作台、房源相关进入时隐藏添加带看
            if(this.$route.query.room_id){
              this.roomHouse_id = this.$route.query.room_id
              this.houseRelevantFlag = false
            }
			// this.seeWithGetPassword()//获取密码接口
        },
        methods: {
			showMobile(item) {
				let that = this;
				that.temporaryOwnerMobile = item.mobile;
				that.temporaryOwnerName = item.userName
				that.MobileShow = !that.MobileShow;
			},
			// 关闭电话弹窗
			hideDialog2() {
				let that = this;
				that.MobileShow = false;
			},
			//获取房门密码
			getDoorPassword() {
				let that = this;
				// that.temporaryDoorPassWord = e.row.ownerMobile;
				// that.temporaryOwnerName = e.row.ownerName
				that.temporaryDoorPassWord = that.privatePwd
				that.temporaryPublicDoorPwd = that.publicPwd
				that.isMobileShow = !that.isMobileShow;
			},
			//关闭获取密码弹窗
			hideDialog() {
				let that = this;
				that.isMobileShow = false;
			},
			//获取密码接口
			seeWithGetPassword (item) {
				// console.log(seeWith_id, longitude, latitude)
				var that = this
				//如果带看时间不是当天，显示*
				if(item.appointmentTime != util.dateFormat(new Date())  ){

					that.publicPwd = "******"
					that.privatePwd  = "******"
					that.getDoorPassword()
					return

				}
				let initData = {
					seeWith_id: item.seeWith_id,
					longitude: item.longitude,
					latitude: item.latitude,
                    setHouse_id: item.setHouse_id,
                    roomHouse_id: item.room_id,
                    type: '1',
                    user_id: globaluserId()

				}

				seeWithGetPassword(initData).then(function(response) {
					console.log(response)
					responseUtil.dealResponse(that, response, () => {
						if (response.data.code == 0) {
							that.publicPwd = response.data.data.publicPwd;
							that.privatePwd = response.data.data.privatePwd;
						}
						that.getDoorPassword()
					})
				})
			},
            //页面跳转checkTakeALook
            checkTakeALook(item){
                let isHaveDevice = '1';
                if(item.publicDeviceId){
                    isHaveDevice = '0'
                }
                this.$router.push({
                    name: 'checkTakeALook',
                    query: {
                        seeWith_id:item.seeWith_id,//带看id
                        longitude:item.longitude,//经度
                        latitude:item.latitude,//纬度
                        room_id:item.room_id,//间id
                        sethouseId:item.setHouse_id, //套id
                        seeWithResult:item.appointmentStatus,//带看状态
                        isHaveDevice:isHaveDevice
                    }
                })
            },
            pageJump() {
                this.$router.push({
                    name: 'addTakeALook',
                    query: {
                    // id:item[0].userId,
                    // userName:item[0].userName
                        userId:this.$route.query.userId,
                        userName:this.$route.query.userName
                    }
                })
            },
            //更改选择类型
            selectType(value){
                this.type = value.dictionaryTitle
                this.typeValue = value.dictionaryValue
                this.DropdownMenuShow = false
                this.takeALookList = []
                this.currentPage = 1
                this.finished = false;
                this.loading = true;
                this.isEmptuyFlag = false;
                this.onLoad()
            },
            onSearch(){
                this.currentPage = 1
                this.takeALookList = []
                this.init()
            },
            //  初始化字典数据
            initDicData() {
                const that = this
                let initData = {}
                initData.fdName = ['APPOINTMENTSTATUSMAP']
                queryBaseData(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.typeList = response.data.data.APPOINTMENTSTATUSMAP
                        that.type = that.typeList[0].dictionaryTitle
                        that.typeValue = that.typeList[0].dictionaryValue
                    })
                })

            },
            //页面初始化后台数据，默认加载全部租约变更信息
            init() {
                let that = this
                that.isEmptuyFlag = false
                let initData = {

                }
                initData.user_id = globaluserId()
                initData.staff_id = getStaffId()
                initData.searchName = that.searchValue
                if(this.fromType == '3' && this.roomHouse_id != ''){
                  initData.appointmentStatus = "1,2"   // 看好  未看好
                  initData.roomHouse_id = this.roomHouse_id  // 间房源id
                }else{
                  initData.appointmentStatus = that.typeValue || "0"
                }
                initData.prId = that.id
                initData.currentPage = that.currentPage || 1
                initData.numberPage = 10
                seeWithList(initData).then(response=>{
                    responseUtil.dealResponse(that,response,()=>{
                        // 清空列表数据
                        // this.takeALookList = [];
                        let list = []
                        that.currentPage++;
                        list = response.data.data.data
                        that.takeALookList = list
                        that.totalPage = response.data.data.totalPage
                        that.pageCount = response.data.data.pageCount

                        // 加载状态结束
                        that.loading = false;

                        // this.refreshing = false;

						//通过带看时间判断是否显示密码按钮
						// for(var i = 0; i < list.length; i++){

						// 	if(list[i].appointmentTime != util.dateFormat(new Date())  ){

						// 		list[i].publicDeviceId = false
						// 	}
						// }



                        if (that.takeALookList.length >= that.pageCount) {
                            that.finished = true;
                            // if(this.pageCount==0){
                            //     this.noContent = true
                            // }
                        }
                        /*if(that.takeALookList){
                            that.takeALookList.forEach(item=>{
                                if(item.bedroomCount||item.livingroomCount||item.kitchenCount||item.bathroomCount||item.balconyCount){
									item.apartment =
										`${item.bedroomCount?item.bedroomCount:'0'}室${item.livingroomCount?item.livingroomCount:'0'}厅${item.kitchenCount?item.kitchenCount:'0'}厨${item.bedroomCount?item.bedroomCount:'0'}卫${item.balconyCount?item.balconyCount:'0'}台`
                                }
                            })
                        }else{
                            that.isAll = false
                        }*/
                        if(response.data.data.pageCount == 0){
                            that.isEmptuyFlag = true
                        }else{
                            that.isEmptuyFlag = false
                        }
                    })
                })
            },

            onLoad() {
                    // if (this.refreshing) {
                    //     this.takeALookList = [];
                    //     this.refreshing = false;
                    //     this.currentPage = 1
                    // }
                    // this.loading = false;

                    // if (this.currentPage <= this.totalPage) {
                    //     this.init()
                    // }
                this.init();
            },
            onRefresh() {
			    this.takeALookList = [];
			    this.currentPage = 1;
                this.finished = false;
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.refreshing = false;
                this.isEmptuyFlag = false;
                this.onLoad();
            },

        },
        mounted(){
            this.id = this.$route.query.id
            console.log(this.$route.query.userId+"   "+this.$route.query.userName)
            this.initDicData()
            // this.init()
        }
    }
</script>

<style scoped>
    .takeALook {
        width: 100%;
        overflow: hidden;
        height: auto;
        background-color:#f7f8fa;
    }

    .navbar {
        z-index: 10;
        background-color: #f8f8f8;
    }

    .head {
        width: 100%;
        position: fixed;
        z-index: 8;
        overflow: hidden;
        background: #f8f8f8;
    }

    /* .search {
        margin: 0 20px;
        height: 35px;
        border-radius: 30px;
        background-color: #f4f4f4;
        display: flex;
        align-items: center;
    } */
.search{
    /*background-color: #f8f8f8;*/
}
    .search-icon {
        width: 25px;
        height: 25px;
        margin-left: 10px;
        background-image: url("../../assets/images/search-icon.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }

	input,
	.searchBody {
        font-size: 14px;
        margin-left: 15px;
        background-color: #f4f4f4;
        border: 0;
        padding: 0;
        width: 250px;
    }

    input::-webkit-input-placeholder {
        color: #d9d9d9;
    }

    .types {
        width: 100%;
        height: 45px;
        display: flex;
    }

    .type {
        width: auto;
        height: 15px;
        font-size: 14px;
        color: #fe5e3a;
        margin: 15px 0 0 15px;
    }

    .tel_IMg {
        float: left;
        width: 15px;
        margin-right: 15px;
        line-height: 39px
    }

    .tel {
        width: 19px;
        height: 18px;
    }

    .small-icon {
        width: 8px;
        height: 8px;
        margin: 22px 0 0 5px;
        background-image: url("../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .small-icon-rotate {
        width: 8px;
        height: 8px;
        margin: 19px 0 0 5px;
        background-image: url("../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        transform: rotate(180deg);
    }

    .selectList {
        position: fixed;
        z-index: 9;
        width: 346px;
        height: 210px;
        top: 135px;
        left: 14px;
        background-color: #f4f4f4;
        border-radius: 8px;
    }

    .select {
        width: 100%;
        height: 53px;
        line-height: 53px;
        font-size: 14px;
        text-align: center;
        color: #999999;
    }

    .selectActive {
        width: 100%;
        height: 53px;
        line-height: 53px;
        font-size: 14px;
        text-align: center;
        color: #fe5e3a;
    }

    .pwd_IMG {
        float: left;
        width: 25px;
        height: 20px;
        margin-left: 15px;
        margin-top: 20px;
    }

    .getPwd {
		/* float: left; */
        margin-right: 150px;
        color: #9a9a9a;
		margin-top: 22px;
		position: absolute;
    }

    .getPwdDisabled {
        float: left;
        margin-right: 150px;
        color: #9a9a9a;
        margin-top: 22px;
        opacity: 0.4;
    }

    .h-line {
        height: 1px;
        margin: 0 5px 0 10px;
        background-color: white;
    }

    .reviews {
        width: 100%;
        /*overflow: hidden;*/
        /*height: auto;*/
        /*position: relative;*/
        /*top: 140px;*/
        background: #FAFAFA;
        bottom: 1px;
    }

    .review {
        margin: 0 15px 15px 15px;
        height: auto;
        background-color: white;
        border-radius: 8px;
        overflow: hidden;
    }

    .btn {
        float: left;
        height: 30px;
        width: 89px;
        font-size: 14px;
		line-height: 30px;
        margin-right: 5px;
        border: 1px solid transparent;
        border-radius: 10px;
        background-image: linear-gradient(white, white),
            linear-gradient(to right, #ffc274, #ff5D3B);
        background-clip: padding-box, border-box;
        background-origin: border-box;
        color: #ff5809;
        text-align: center;
    }

	.btn2 {
		float: left;
		height: 30px;
		width: 80px;
		font-size: 14px;
		line-height: 30px;
		margin-right: 5px;
		border: 1px solid transparent;
		border-radius: 10px;
		background-image: linear-gradient(white, white),
			linear-gradient(to right, #ffeccb, #ffc2a6);
		background-clip: padding-box, border-box;
		background-origin: border-box;
		color: #ffdaac;
		text-align: center;
	}

    .btnDisabled {
        float: left;
        height: 30px;
        width: 89px;
        font-size: 14px;
        line-height: 31px;
        border: 1px solid transparent;
        border-radius: 10px;
        background-image: linear-gradient(white, white),
            linear-gradient(to right, #ffc274, #ff5D3B);
        background-clip: padding-box, border-box;
        background-origin: border-box;
        color: #ff5809;
        text-align: center;
        opacity: 0.4;
    }

    .top-title-orange {
        width: 45px;
        height: 17px;
        font-size: 10px;
        text-align: center;
        line-height: 21px;
        border-radius: 8px 0;
        background: linear-gradient(to right, #ffbe72, #ff6c41);
        color: white;
    }

    .top-title-green {
        width: 45px;
        height: 17px;
        font-size: 10px;
        text-align: center;
        line-height: 21px;
        border-radius: 8px 0;
        background: linear-gradient(to right, #63cb96, #30d0ac);
        color: white;
    }

    .top-title-red {
        width: 45px;
        height: 17px;
        font-size: 10px;
        text-align: center;
        line-height: 21px;
        border-radius: 8px 0;
        background: linear-gradient(to right, #FF4500, #FF0000);
        color: white;
    }

    .top-title-gray {
        width: 45px;
        height: 17px;
        font-size: 10px;
        text-align: center;
        line-height: 21px;
        border-radius: 8px 0;
        background-color: #9a9a9a;
        color: white;
    }

    .position {
        margin: 15px 12px 0 15px;
        height: auto;
        text-align: left;
        font-size: 14px;
        font-weight: bold;
        width: 282px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        float: left;
        /*overflow: hidden;!*超出部分隐藏*!*/
        /*white-space: nowrap;!*不换行*!*/
        /*text-overflow:ellipsis;!*超出部分文字以...显示*!*/
    }

    .position-span-left {
        line-height: 20px;
        color: #fe5e3a;
    }

    .position-span-right {
        line-height: 20px;
        color: black;
    }

    .details {
        margin-top: 10px;
        width: 100%;
        height: auto;
        border-bottom: 1px solid #f4f4f4;
        display: flex;
    }

    .details .info {
        width: 114px;
        height: auto;
        text-align: center;
        margin-top: 10px;
    }

    .details .info .order {
        width: 100%;
        height: 20px;
        font-size: 14px;
        line-height: 20px;
        color: #999999;
    }

    .details .info .order-name {
        width: 100%;
        height: 20px;
        font-size: 14px;
        line-height: 20px;
        color: #fe5e3a;
        margin-top: 10px;
        margin-bottom: 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: bold;
    }

    .details .line {
        width: 1px;
        height: 40px;
        background-color: #f4f4f4;
        margin-top: 18px;
    }

    .reject {
        width: 100%;
        height: auto;
        border-bottom: 1px solid #f4f4f4;
    }

    .reject-num {
        margin: 15px 15px 5px 15px;
        color: #999999;
        font-size: 14px;
    }

    .span-num {
        color: #9a9a9a;
    }

    .reject-reason {
        margin: 0 15px 15px 15px;
        color: #999999;
        font-size: 14px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .bottom {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        font-size: 14px;
    }

    .buttonWrap {
        position: fixed;
        bottom: -2px;
        background-color: #fff;
        padding: 10px 15px;
    }

    .fillContent {
        width: 100% ;
        height: 65px;
        visibility: hidden;
    }

    .addUser {
        font-size: 16px;
        color: white;
        border-radius: 9px;
        width: 345px;
        height: 45px;
        border: none;
        background: linear-gradient(to right, #77b2ff, dodgerblue);
    }

    .pwdHide {
        display: none;
    }

    .pwdShow {
        display: block;
    }
</style>
